@import url("https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@400..900&display=swap");

.rajdhani-light {
  font-family: "Rajdhani", serif;
  font-weight: 300;
  font-style: normal;
}

.rajdhani-regular {
  font-family: "Rajdhani", serif;
  font-weight: 400;
  font-style: normal;
}

.rajdhani-medium {
  font-family: "Rajdhani", serif;
  font-weight: 500;
  font-style: normal;
}

.rajdhani-semibold {
  font-family: "Rajdhani", serif;
  font-weight: 600;
  font-style: normal;
}

.rajdhani-bold {
  font-family: "Rajdhani", serif;
  font-weight: 700;
  font-style: normal;
}

.orbitron-regular {
  font-family: "Orbitron", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.orbitron-semibold {
  font-family: "Orbitron", serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
}

/* CSS */

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #0a0a0a;
}

::-webkit-scrollbar-thumb {
  background: #ffa0c5;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #ffa0c5;
}

@keyframes headerBloom {

  0%,
  100% {
    filter: drop-shadow(0 0 5px #ffb7c5) drop-shadow(0 0 15px rgb(255, 160, 197, 0.2));
  }

  50% {
    filter: drop-shadow(0 0 10px #ffb7c5) drop-shadow(0 0 25px rgb(255, 160, 197, 0.2)) drop-shadow(0 0 35px rgb(255, 160, 197, 0.3));
  }
}

.ascii-bloom {
  display: inline-block;
  animation: headerBloom 3s ease-in-out infinite;
  will-change: filter;
}

.cyberpunk-button {
  position: relative;
  overflow: hidden;
  padding: 0.75rem 2rem;
  font-weight: 700;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.3s;
}

.cyberpunk-button.primary {
  background-color: #ffa0c5;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}

.cyberpunk-button.secondary {
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: #ffa0c5;
  --tw-text-opacity: 1;
  color: #ffa0c5;
}

.grid-overlay {
  background-image: linear-gradient(90deg, #00ff9d08 1px, transparent 0),
    linear-gradient(180deg, #00ff9d08 1px, transparent 0);
  background-size: 50px 50px;
  mask-image: linear-gradient(180deg,
      transparent,
      #000 10%,
      #000 90%,
      transparent);
}

@keyframes scan {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(100vh);
  }
}

.cyberpunk-scan {
  position: relative;
  overflow: hidden;
}

.cyberpunk-scan:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #ffb7c5;
  animation: scan 2s ease-in-out infinite;
  box-shadow: 0 0 10px #ffb7c5;
  opacity: 0.5;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in {
  animation: fadeIn 1s ease-out;
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}

pre {
  display: block;
  font-family: monospace;
  unicode-bidi: isolate;
  white-space: pre;
  margin: 1em 0px;
}

.backdrop-blur-md {
  --tw-backdrop-blur: blur(12px);
}

.blob {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background-image: linear-gradient(#ffa0c5 1%, rgb(255, 160, 197, 0.6));
  filter: blur(50px);
  transition: all 0.1ms ease-out;
  position: fixed;
  pointer-events: none;
  left: 0;
  top: 0;
  transform: translate(calc(-50% + 15px), -50%);
}

.origin-left {
  transform-origin: left;
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.bg-terminal {
  background-image: repeating-linear-gradient(0deg,
      rgba(0, 255, 0, 0.15),
      rgba(0, 255, 0, 0.15) 1px,
      transparent 1px,
      transparent 2px);
  background-size: 100% 2px;
  animation: 8s linear 0s infinite normal none running noise;
}

.terminal-inner {
  animation: 0.15s ease 0s infinite normal none running flicker;
  box-shadow: rgba(0, 255, 0, 0.2) 0px 0px 20px,
    rgba(0, 255, 0, 0.1) 0px 0px 20px inset;
}

.terminal-content {
  background: rgba(0, 0, 0, 0.9);
}

.typing {
  animation: typing 2s steps(22), blink 0.5s step-end infinite alternate;
  border-right: 8px solid;
  background-color: #ffa0c5;
  color: #000;

}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

.progress-wrapper {
  width: 300px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
}

.progress-wrapper>.progress-container {
  width: 100%;
  height: 20px;
  background: #000;
  border: 1px solid #ffa0c5;
}

.progress-wrapper>.progress-container>.progress {
  width: 0%;
  height: 20px;
  background: #ffa0c5;
}